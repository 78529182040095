.container {
  overflow: hidden;
  .container-app {
    margin-bottom: 100px;
    width: 100%;
    // float: right;
    padding: 0 20px;
    @media screen and (max-width: 768px){
      width: 100%;
      padding: unset;
    }
  }
}
